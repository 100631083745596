(function () {
  'use strict';

  /**
   * @ngdoc directive
   * @name components.directive:navBar
   * @restrict EA
   * @element
   *
   * @description
   *
   * @example
     <example module="components">
       <file name="index.html">
        <nav-bar></nav-bar>
       </file>
     </example>
   *
   */
  angular
    .module('components')
    .directive('navBar', navBar);

  function navBar() {
    return {
      restrict: 'EA',
      scope: {},
      templateUrl: 'components/nav-bar/nav-bar-directive.tpl.html',
      replace: false,
      controllerAs: 'navBar',
      controller: 'NavBarCtrl'
    };
  }
}());
